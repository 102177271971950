var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: { width: "600" },
      scopedSlots: _vm._u(
        [
          {
            key: "activator",
            fn: function ({ on, attrs }) {
              return [
                _c(
                  "div",
                  _vm._g(_vm._b({}, "div", attrs, false), on),
                  [_vm._t("default")],
                  2
                ),
              ]
            },
          },
        ],
        null,
        true
      ),
      model: {
        value: _vm.scheduleDialog,
        callback: function ($$v) {
          _vm.scheduleDialog = $$v
        },
        expression: "scheduleDialog",
      },
    },
    [
      _c(
        "v-card",
        [
          _c("v-card-title", [_vm._v("Schedule")]),
          _c(
            "v-card-text",
            { staticClass: "pt-3" },
            [
              _c(
                "v-radio-group",
                {
                  staticClass: "mt-0 pt-0",
                  attrs: { "hide-details": "" },
                  model: {
                    value: _vm.scheduleVersion,
                    callback: function ($$v) {
                      _vm.scheduleVersion = $$v
                    },
                    expression: "scheduleVersion",
                  },
                },
                [
                  _c(
                    "v-container",
                    { staticClass: "pa-0", attrs: { fluid: "" } },
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            [
                              _c(
                                "v-card",
                                {
                                  attrs: {
                                    flat: "",
                                    color:
                                      _vm.scheduleVersion === "later"
                                        ? "grey lighten-3"
                                        : "",
                                    outlined: "",
                                  },
                                },
                                [
                                  _c("v-radio", {
                                    staticClass: "pl-4",
                                    attrs: { value: "later" },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "label",
                                        fn: function () {
                                          return [
                                            _c(
                                              "v-card-text",
                                              { staticClass: "text-button" },
                                              [
                                                _vm._v(" Schedule "),
                                                _c("b", [_vm._v("later")]),
                                              ]
                                            ),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                    ]),
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            [
                              _c(
                                "v-card",
                                {
                                  attrs: {
                                    flat: "",
                                    color:
                                      _vm.scheduleVersion === "now"
                                        ? "grey lighten-3"
                                        : "",
                                    outlined: "",
                                  },
                                },
                                [
                                  _c("v-radio", {
                                    staticClass: "pl-4",
                                    attrs: { value: "now" },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "label",
                                        fn: function () {
                                          return [
                                            _c(
                                              "v-card-text",
                                              { staticClass: "text-button" },
                                              [
                                                _vm._v(" Schedule "),
                                                _c("b", [_vm._v("now")]),
                                              ]
                                            ),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                    ]),
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.scheduleVersion === "later"
                ? _c(
                    "v-card",
                    {
                      staticClass: "mt-4",
                      attrs: { flat: "", color: "grey lighten-3" },
                    },
                    [
                      _c(
                        "v-card-text",
                        { staticClass: "py-6" },
                        [
                          _c(
                            "v-menu",
                            {
                              attrs: {
                                "close-on-content-click": false,
                                "nudge-right": 40,
                                transition: "scale-transition",
                                "offset-y": "",
                                "min-width": "auto",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function ({ on, attrs }) {
                                      return [
                                        _c(
                                          "v-text-field",
                                          _vm._g(
                                            _vm._b(
                                              {
                                                staticClass: "mb-4",
                                                attrs: {
                                                  label: "Select a date",
                                                  "prepend-icon":
                                                    "mdi-calendar",
                                                  readonly: "",
                                                  outlined: "",
                                                  dense: "",
                                                  "hide-details": "",
                                                },
                                                model: {
                                                  value: _vm.scheduleDate,
                                                  callback: function ($$v) {
                                                    _vm.scheduleDate = $$v
                                                  },
                                                  expression: "scheduleDate",
                                                },
                                              },
                                              "v-text-field",
                                              attrs,
                                              false
                                            ),
                                            on
                                          )
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                3258156840
                              ),
                              model: {
                                value: _vm.scheduleDateMenu,
                                callback: function ($$v) {
                                  _vm.scheduleDateMenu = $$v
                                },
                                expression: "scheduleDateMenu",
                              },
                            },
                            [
                              _c("v-date-picker", {
                                attrs: {
                                  min: _vm.scheduleLaterThan.toISODate(),
                                  max: _vm.scheduleEarlierThan.toISODate(),
                                },
                                on: {
                                  input: function ($event) {
                                    _vm.scheduleDateMenu = false
                                  },
                                },
                                model: {
                                  value: _vm.scheduleDate,
                                  callback: function ($$v) {
                                    _vm.scheduleDate = $$v
                                  },
                                  expression: "scheduleDate",
                                },
                              }),
                            ],
                            1
                          ),
                          _c("time-picker", {
                            attrs: {
                              date: _vm.scheduleDate,
                              min: _vm.minAvailableTime,
                              max: _vm.maxAvailableTime,
                              format: "24hr",
                            },
                            model: {
                              value: _vm.scheduleTime,
                              callback: function ($$v) {
                                _vm.scheduleTime = $$v
                              },
                              expression: "scheduleTime",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.currentSpaceData?.beegfs_sync
                ? _c(
                    "v-card",
                    { attrs: { flat: "", color: "mt-4", outlined: "" } },
                    [
                      _c(
                        "v-card-title",
                        [
                          _c("v-switch", {
                            staticClass: "mt-0",
                            attrs: {
                              "hide-details": "",
                              label: "Scale resources",
                            },
                            model: {
                              value: _vm.scaleResources,
                              callback: function ($$v) {
                                _vm.scaleResources = $$v
                              },
                              expression: "scaleResources",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-card-text",
                        [
                          _c("v-select", {
                            attrs: {
                              outlined: "",
                              dense: "",
                              "return-object": "",
                              "item-text": "gpu_type",
                              "item-value": "node_pool",
                              disabled: !_vm.scaleResources,
                              items: _vm.gpuNodes,
                              label: "Select resource",
                              loading: _vm.fetching,
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "item",
                                  fn: function ({ item, on, attrs }) {
                                    return [
                                      _c(
                                        "v-list-item",
                                        _vm._g(
                                          _vm._b(
                                            { attrs: { "two-line": "" } },
                                            "v-list-item",
                                            attrs,
                                            false
                                          ),
                                          on
                                        ),
                                        [
                                          _c(
                                            "v-list-item-content",
                                            [
                                              _c("v-list-item-title", [
                                                _c("b", [
                                                  _vm._v(_vm._s(item.gpu_type)),
                                                ]),
                                              ]),
                                              _c("v-list-item-subtitle", [
                                                _vm._v(
                                                  "vCPU: " +
                                                    _vm._s(item.cpu) +
                                                    ", RAM: " +
                                                    _vm._s(item.memory) +
                                                    "GB"
                                                ),
                                              ]),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              2515677967
                            ),
                            model: {
                              value: _vm.selectedResource,
                              callback: function ($$v) {
                                _vm.selectedResource = $$v
                              },
                              expression: "selectedResource",
                            },
                          }),
                          _c("v-text-field", {
                            ref: "stopAfter",
                            attrs: {
                              label: "Stop after selected minutes",
                              outlined: "",
                              dense: "",
                              disabled: !_vm.scaleResources,
                              min: _vm.minutesMin,
                              max: _vm.minutesMax,
                              step: _vm.minutesStep,
                              type: "number",
                              "prepend-icon": "mdi-timer-stop-outline",
                              rules: [
                                _vm.rules.integer,
                                _vm.rules.required,
                                _vm.rules.gte(_vm.minutesMin),
                                _vm.rules.lte(_vm.minutesMax),
                                _vm.rules.increment,
                              ],
                            },
                            model: {
                              value: _vm.selectedMinutes,
                              callback: function ($$v) {
                                _vm.selectedMinutes = $$v
                              },
                              expression: "selectedMinutes",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { color: "secondary", text: "" },
                  on: {
                    click: function ($event) {
                      _vm.scheduleDialog = false
                    },
                  },
                },
                [_vm._v("Cancel")]
              ),
              _c(
                "v-btn",
                {
                  attrs: {
                    color: "primary",
                    text: "",
                    loading: _vm.loading || _vm.fetching,
                  },
                  on: { click: _vm.crudCall },
                },
                [_vm._v("Set schedule")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }